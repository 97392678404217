import React from 'react';
import { List, Typography, Row, Grid, Divider } from 'antd';
import { newsData } from './data/data';
import karImg from './assets/Kardiogen.jpg';
const products = [
  {
    name: 'KARDIOGEN',
    description: `Polygenic Risk Score of Coronary Artery Disease
<b>sampleType:</b> 3 – 4ml Whole Blood Sample/EDTA tube
<b>turnAroundTime:</b> 28 days
<b>laboratory:</b> MedGenome Lab (India)
<b>capAccredition:</b> YES
    `,
    img: karImg
  }
]
const { Title, Paragraph } = Typography;


const OurProduct = () => {
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint(); // Get the current screen size
  return (
    <div style={{ padding: '20px' }}>
      <h2 style={{textAlign:'center'}}> Our Products </h2>
      <Row gutter={[20, 20]} justify="center" style={{display:'flex'}}> {/* Sets padding between items to 10px */}
        <List
          grid={{ gutter: 16, column: 1 }}
          dataSource={products}
          renderItem={item => (
            <List.Item>
              <div style={{ display: 'flex', width: screens.md?'75%':'100%' }}>
                <img
                  src={item.img}
                  alt={item.title}
                  style={{ width:'30%', objectFit: 'cover', marginRight: '20px' }}
                />
                <div style={{ flex: 1 }}>
                  <Title level={4}>
                    {item.name}
                  </Title>
                  <Paragraph style={{ marginTop: '8px', whiteSpace:'pre-wrap' }}>
                    <span dangerouslySetInnerHTML={{ __html: item.description }} />
                    {/* {item.contents && item.contents[0] && item.contents[0].paragraph && truncateText(item.contents[0].paragraph, 30)} */}
                  </Paragraph>
                  <a href='/kardiogen.pdf' download>Product manual</a>
                </div>
              </div>
              <Divider style={{ width: '100%' }} />
            </List.Item>
          )}
        />
      </Row>

    </div>
  );
};

export default OurProduct;
