import React from 'react';
import { Menu, Divider, Button, Layout } from 'antd';
import { HomeOutlined, UserAddOutlined } from '@ant-design/icons';
import { useNavigate, useLocation } from 'react-router-dom';
import logo from './assets/logo.png';
import './Navigation.css';

const Navigation = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = (e) => {
    navigate(e.key);
  };

  return (
    <div style={{ position:'fixed', width:'200px', display:'flex', height:'100vh', flexDirection:'column', justifyContent:'space-between', backgroundColor:'#fff'}}>
      <div>
        <div style={{display:'flex', paddingTop: '25px', flexDirection: 'column', alignItems:'center', justifyContent:'center', marginBottom:'15px'}}>
            <img src={logo} alt="cover"  style={{width:'60%' }}/>
        </div>
        <Menu
        mode="vertical"
        selectedKeys={[location.pathname]} // Highlight the current route
        onClick={handleClick}
        style={{border: 0}}
        >
          <Divider />
          <Menu.Item key="/dashboard" icon={<HomeOutlined />}>
            Trang chủ
          </Menu.Item>
          <Menu.Item key="/account" icon={<UserAddOutlined />}>
            Tài khoản
          </Menu.Item>
        </Menu>
      </div>
      <div style={{textAlign:'center', margin: '20px 0'}}>
        <Button type="primary" htmlType="submit" style={{height: '40px', width:'70%', boxShadow:'none'}} onClick={()=>{
          localStorage.removeItem('token');
          navigate('/login');
        }}>  
          <span><b>Đăng xuất</b></span>
        </Button>
      </div>
    </div>
  );
};

export default Navigation;