import React, {useCallback, useEffect, useState} from 'react';
import { Card, Typography, Col, Row } from 'antd';
import './DashboardPage.css';
import TableContent from './TableContent';
import { backendUrl } from '../../constants';
import { fetchWithToken } from '../share/apiUtils';

const { Title } = Typography;
const DashboardPage = () => {
  const [countByStatusData, setCountByStatusData] = useState([]);
  const fetchCountData = useCallback( async () => {
    try {
      const baseUrl = `${backendUrl}/count_by_status`;
      const url = new URL(baseUrl);
      const response = await fetchWithToken(url.toString(), {
        method: 'GET',
      });
  
      if (!response.ok) {
        throw new Error(`Failed to fetch: ${response.status} ${response.statusText}`);
      }
  
      const data = await response.json();
      setCountByStatusData(data);
      
    } catch (error) {
      console.error('Error fetching options:', error);
      return [];
    }
  }, []);

  useEffect(() => {
    fetchCountData();
  }, [fetchCountData]);

  return (
    <>
      <div>
        <Title level={4} style={{textAlign:'left'}}>Trang chủ</Title>
      </div>
      <div style={{width:'100%', margin: '30px 0'}}>
        <Card bordered={false} style={{padding:0}} >
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col className="gutter-row" span={6}>
              <div className='dashboard-header'>
                <Title level={5} style={{textAlign:'left',}}>Số mẫu xét nghiệm mới</Title>
                <Title level={5} style={{textAlign:'left'}}>{countByStatusData['NEW'] || 0}</Title>
              </div>
            </Col>
            <Col className="gutter-row" span={6}>
              <div className='dashboard-header'>
                <Title type="danger" level={5} style={{textAlign:'left'}}>Số mẫu chờ thanh toán</Title>
                <Title type="danger" level={5} style={{textAlign:'left'}}>{countByStatusData['WAITING_FOR_PAYMENT'] || 0}</Title>
              </div>
            </Col>
            <Col className="gutter-row" span={6}>
              <div className='dashboard-header'>
                <Title type='warning' level={5} style={{textAlign:'left'}}>Số mẫu đang xử lý</Title>
                <Title type='warning' level={5} style={{textAlign:'left'}}>{countByStatusData['PROCESSING'] || 0}</Title>
              </div>
            </Col>
            <Col className="gutter-row" span={6}>
              <div className='dashboard-header'>
                <Title type='success' level={5} style={{textAlign:'left'}}>Số mẫu đã xử lý</Title>
                <Title type='success' level={5} style={{textAlign:'left'}}>{countByStatusData['COMPLETED'] || 0}</Title>
              </div>
            </Col>
          </Row>
        </Card>
      </div>

      <TableContent />
    </>
  );
};

export default DashboardPage;