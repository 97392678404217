import React from 'react';
import Navigation from '../Navigation/Navigation';
import { Col, Row } from 'antd';

const MainLayout = ({ children }) => {
  return (
    <Row className="customPageContent">
        <Col style={{ width:'200px'}}>
          <Navigation />
        </Col>
        <Col style={{ padding: '20px', marginLeft:'200px', minHeight: '100vh'}}>
          {children}
        </Col>
    </Row>
  );
};

export default MainLayout;
