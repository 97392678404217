// src/apiUtils.js
const getAuthToken = () => {
  return localStorage.getItem('token');
};

const handleAuthError = () => {
  // Clear the token or perform any other clean-up actions
  localStorage.removeItem('token');
  
  // Redirect to the login page
  window.location.href = '/login'; // Adjust the path based on your routing setup
};

export const fetchWithToken = async (url, options = {}) => {
  const token = getAuthToken();

  const headers = {
    'Content-Type': 'application/json',
    ...options.headers,
  };

  if (token) {
    headers['Authorization'] = `Bearer ${token}`;
  }

  try {
    const response = await fetch(url, {
      ...options,
      headers,
    });

    if (response.status === 401 || response.status === 403) {
      handleAuthError();
      return; // Return early, no further processing
    }

    return response;
  } catch (error) {
    console.error('Error fetching data:', error);
    return error;
  }
};
