import React, {useState, useEffect} from 'react';
import { Layout, Menu, Row, Col, Divider, Grid, Input, Card } from 'antd';
import '../LandingPage.css'; // Optional for custom styling
import logo from '../assets/logo.png';
import { SearchOutlined, MenuOutlined, CloseOutlined } from '@ant-design/icons';
import MenuPage from '../MenuPage';

const { useBreakpoint } = Grid;

const { Header, Content } = Layout;

const NavigationLandingPage = () => {
  const [bannerHeight, setBannerHeight] = useState('auto');
  const [menuState, setMenuState] = useState('content');
  const screens = useBreakpoint(); // Get the current screen size

  useEffect(() => {
    const handleResize = () => {
      // Calculate available height for banner
      const headerHeight = document.querySelector('.header').offsetHeight;
      setBannerHeight(`100vh`);
    };

    // Set initial height
    handleResize();
    
    // Add resize event listener
    window.addEventListener('resize', handleResize);

    // Clean up on unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div style={{width:'100%'}}>
      <div style={{backgroundColor:'#fff', display:'flex', position:'fixed', width:'100%', borderBottom:'1px solid rgba(5, 5, 5, 0.06)', zIndex:"200", flexDirection:'column'}}>
        <Header className="header"  style={{backgroundColor:'#fff',width:'100%', padding: '20px'}}>
          <Row style={{display:'flex', width:'100%'}}>
            <Col span={12}>
              <a href="/"><img src={logo} alt='logo' style={{width:screens.md ?'25%':'50%'}}/></a>
            </Col>
            <Col span={12}>
              {
                menuState === 'content' && (
                  <Menu mode="horizontal"   style={{ width: '100%', display:'flex', justifyContent:'end' }}>
                    <Menu.Item key="search" icon={<SearchOutlined />} onClick={() => {
                      setMenuState('search');
                    }}>
                      {/* Conditionally render the text based on screen size */}
                      {screens.md ? 'Search Everything' : null}
                    </Menu.Item>
                    <Menu.Item key="menu" icon={<MenuOutlined />} onClick={() => {
                      setMenuState('menu');
                    }}>
                      Menu
                    </Menu.Item>
                  </Menu>
                )
              }

              {
                (menuState === 'search' || menuState === 'menu') && (
                  <Menu selectable={false} mode="horizontal" style={{ width: '100%', display:'flex', justifyContent:'end', border:'none'}}>
                    <Menu.Item key="menu" icon={<CloseOutlined />} onClick={() => {
                      setMenuState('content');
                    }}>
                      Close
                    </Menu.Item>
                  </Menu>
                )
              }
            </Col>
          </Row>
  
          
        </Header>
        {menuState === 'search' && (<div id='header-search' style={{ height: bannerHeight, backgroundColor:'#fff'}}>
          <Content className="header" style={{display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column', width: screens.md ? '60%' : '80%', margin:'30px auto', zIndex:30}}>
            <h2>What can we help you find?</h2>
            <Input
              prefix={<SearchOutlined style={{ color: '#001628', fontSize:'1.5em', margin:'0 10px' }} />} // Set icon color
              placeholder="Search"
              style={{
                borderColor: '#001628', // Change border color
                color: '#001628', // Change text color
                height: '70px', // Increase height
              }}
            />
          </Content>
        </div>)}
        { menuState === 'menu' && (
          <MenuPage height={bannerHeight} />
        )}
      </div>
      
     
    </div>
  );
};

export default NavigationLandingPage;