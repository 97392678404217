import React, {useState, useEffect} from "react";
import { Card, Row, Col, Breadcrumb, Typography, Image , Grid, List, Divider} from "antd";
import NavigationLandingPage from "../Layout/NavigationLandingPage";
import FooterLandingPage from "../Layout/FooterLandingPage";
import { newsData }  from "../data/data";
import { useNavigate } from 'react-router-dom';
import Link from "antd/es/typography/Link";
import './new.css'

const { Title, Paragraph } = Typography;

const truncateText = (text, maxWords) => {
  const wordsArray = text.split(' '); // Split text into an array of words
  if (wordsArray.length > maxWords) {
    return wordsArray.slice(0, maxWords).join(' ') + '...'; // Join first 'maxWords' words and add '...'
  }
  return text; // If text is already within the limit, return it unchanged
};

const ListNewPage = () => {
  const [bannerHeight, setBannerHeight] = useState('auto');
  const [headerHeight, setHeaderHeight] = useState(0);
  const navigate = useNavigate();
  const { useBreakpoint } = Grid;

  useEffect(() => {
    const handleResize = () => {
      // Calculate available height for banner
      const headerHeight = document.querySelector('.header').offsetHeight;
      const footerHeight = document.querySelector('.footer-component').offsetHeight;
      setHeaderHeight(headerHeight);
      setBannerHeight(`calc(100vh - ${headerHeight+footerHeight}px)`);
    };

    // Set initial height
    handleResize();
    
    // Add resize event listener
    window.addEventListener('resize', handleResize);

    // Clean up on unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  const screens = useBreakpoint(); // Get the current screen size

  // Find the specific news item based on the ID
  return (
    <div>
      <NavigationLandingPage />
      <div style={{paddingTop:`${headerHeight}px`, minHeight:bannerHeight, padding: `${headerHeight}px ${screens.md?100:30}px`}}>
        <Breadcrumb style={{margin:'10px 0'}}>
            <Breadcrumb.Item>
              <a href="/">Home</a>
            </Breadcrumb.Item>
            <Breadcrumb.Item>News</Breadcrumb.Item>
        </Breadcrumb>
        <Title level={2}>News</Title>
        <Divider />
        <List
          grid={{ gutter: 16, column: 1 }}
          dataSource={newsData}
          renderItem={item => (
            <List.Item>
              <div style={{ display: 'flex', width: screens.md?'75%':'100%' }}>
                <img
                  src={item.imageUrl}
                  alt={item.title}
                  style={{ height: screens.md?'200px':'100px', width:'30%', objectFit: 'cover', marginRight: '20px' }}
                />
                <div style={{ flex: 1 }}>
                  <Link onClick={() => navigate(item.link)}>
                    <Title level={4} style={{ margin: 0 }}>{item.title}</Title>
                  </Link>
                  <Paragraph style={{ color: 'rgba(0, 0, 0, 0.45)', margin: 0 }}>
                    {item.date}
                  </Paragraph>
                  <Paragraph style={{ marginTop: '8px' }}>
                    {item.contents && item.contents[0] && item.contents[0].paragraph && truncateText(item.contents[0].paragraph, 30)}
                  </Paragraph>
                </div>
              </div>
              <Divider style={{ width: '100%' }} />
            </List.Item>
          )}
        />
      </div>
      <FooterLandingPage />
    </div>

  );
};

export default ListNewPage;