import React from 'react';
import { Layout, Row, Col, Divider } from 'antd';
import '../LandingPage.css'; // Optional for custom styling
import logoWhite from '../assets/Logo_HPS_White-01.png';
import logo from '../assets/logo.png';
import ConnectWithUs from '../ConnectWithUs';


const { Content } = Layout;

const FooterLandingPage = () => {

  return (
    <>
    <Content className="our-partners" style={{backgroundColor:'#001628'}}>
          <ConnectWithUs />
        </Content>
        <Layout className='footer-component' style={{borderTop: '1px solid rgba(5, 5, 5, 0.06)'}}>
      {/* <Content className="footer" style={{backgroundColor:'#001628', padding:'20px'}}>
        <Row className="footer-content">
          <Col  sx={24} sm={24} md={8} lg={8} xl={8} className='footer-item' style={{display:'flex', alignItems:'center', paddingBottom:'10px'}}>
            <img src={logoWhite} alt='logo' style={{width:'50%'}}/>
          </Col>
          <Col sx={24} sm={24} md={16} lg={16} xl={16} className='footer-item' style={{display:'flex'}}>
            <h2 style={{color:'#fff'}}>HEALTH PRECISION SERIVCE</h2>
            <p style={{color:'#fff'}}>Address: Room 2 – Floor 8 – Pearl Plaza, 561A Dien Bien Phu Street, Ward 25, Binh Thanh District, Ho Chi Minh City, Vietnam</p>
            <p style={{color:'#fff'}}>Phone: +84 (0) 93 407 8418</p>
            <p style={{color:'#fff'}}>Email: admin@healthprecisionservice.com</p>
          </Col>
        </Row>
      </Content>
      <Divider style={{backgroundColor:'#fff', width:'100vw !important', margin:0}}/>
      <Content className="footer" style={{backgroundColor:'#001628'}}>
        <Row className="footer-content">
          <Col span={24} className='footer-item' style={{alignItems:'center', marginTop:'20px'}}>
            <p style={{color:'#fff'}}>©2024 by Health Precision Service LTD All Rights Reserved</p>
          </Col>
        </Row>
      </Content> */}
              <Content className="footer" style={{backgroundColor:'#fff', padding:'20px'}}>
          <Row className="footer-content">
            <Col  sx={24} sm={24} md={8} lg={8} xl={8} className='footer-item' style={{display:'flex', alignItems:'center', paddingBottom:'10px'}}>
              <img src={logo} alt='logo' style={{width:'50%'}}/>
            </Col>
            <Col sx={24} sm={24} md={16} lg={16} xl={16} className='footer-item' style={{display:'flex'}}>
              <h2 style={{color:'#001628'}}>HEALTH PRECISION SERIVCE</h2>
              <p style={{color:'#001628'}}>Address: Room 2 – Floor 8 – Pearl Plaza, 561A Dien Bien Phu Street, Ward 25, Binh Thanh District, Ho Chi Minh City, Vietnam</p>
              <p style={{color:'#001628'}}>Phone: +84 (0) 93 407 8418</p>
              <p style={{color:'#001628'}}>Email: admin@healthprecisionservice.com</p>
            </Col>
          </Row>
        </Content>
        <Divider style={{backgroundColor:'#a3a3a3', width:'100vw !important', margin:0}}/>
        <Content className="footer" style={{backgroundColor:'#fff'}}>
          <Row className="footer-content">
            <Col span={24} className='footer-item' style={{alignItems:'center', marginTop:'20px'}}>
              <p style={{color:'#001628'}}>©2024 by Health Precision Service LTD All Rights Reserved</p>
            </Col>
          </Row>
        </Content>
    </Layout>
    </>
    
  );
};

export default FooterLandingPage;