import React from 'react';
import taman from './assets/tamanlab.png';
import medgenome from './assets/medgenome.png';


const partnerData = [
  { name: 'TamAn', logoSrc: taman },
  { name: 'medgenome', logoSrc: medgenome },
];

const OurPartners = () => {
  return (
    <div style={{ padding: '20px', display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column'}}>
      <h2>Our Partners</h2>
      <div>
        {partnerData.map((item, index) => (
          <img key={index} src={item.logoSrc} alt={item.name} style={{ height: '70px', margin: '10px 30px' }} />
        ))}
      </div>
    </div>
  );
};

export default OurPartners;
