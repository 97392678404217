import React from 'react';
import { Card, Button, Row, Col } from 'antd';
import { FacebookOutlined, YoutubeOutlined } from '@ant-design/icons';
import Link from 'antd/es/typography/Link';

const socialData = [
  { name: 'Facebook', icon: <FacebookOutlined /> , link: 'https://www.facebook.com/profile.php?id=100066991868943'},
  { name: 'Youtube', icon: <YoutubeOutlined /> , link: 'https://www.youtube.com/@HealthPrecisionServiceCo'},
];

const ConnectWithUs = () => {
  return (
    <Row style={{ padding: '20px', color: '#fff', display:'flex'}}>
      <Col sx={24} sm={24} md={12} lg={12} xl={12} style={{textAlign:'center', display:'flex', justifyContent:'center', alignItems:'center'}}>
        <h2 style={{fontSize:'24px', margin:'0px'}}><b>Connect with Us on social</b></h2>
      </Col>
      <Col sx={24} sm={24} md={12} lg={12} xl={12} style={{textAlign:'center'}} >
        {socialData.map((item, index) => (
          <Link key={index} href={item.link} target="_blank" style={{color:'#fff'}}>
            <span style={{fontSize:'30px', padding: '0 20px'}}>{item.icon}</span>
          </Link>
        ))}
      </Col>
    </Row>
  );
};

export default ConnectWithUs;
