import React from 'react';
import GoogleLoginButton from './GoogleLoginButton';
import { Row, Col, Typography, Input, Form, Button, Checkbox, Divider, Link } from 'antd';
import cover from './assets/cover.png';
import logo from './assets/logo.png';
import './LoginPage.css';

const { Title } = Typography;
const { Item } = Form;

const LoginPage = () => {
  return (
    <Row>
      <Col span={17} style={{}}>
        <img src={cover} alt="cover"  style={{width:'100%' }}/>
        <div class="overlay"></div>
      </Col>
      <Col span={7} style={{paddingTop: '50px', display:'flex', flexDirection:'column', alignItems:'center'}}>
        <div style={{display:'flex', flexDirection: 'column', alignItems:'center', justifyContent:'center', marginBottom:'60px'}}>
          <img src={logo} alt="cover"  style={{width:'60%' }}/>
        </div>
        <div style={{width:'350px'}}>
          <Title level={4} style={{textAlign:'left'}}>Nice to see you again</Title>

          <Form
            name="basic"
            initialValues={{ remember: true }}
            layout="vertical" // This layout option makes labels appear above the input fields
          >
            <Item
              label="Username"
              name="username"
              style={{marginBottom: '15px'}}
            >
              <Input placeholder="Enter your username" />
            </Item>

            <Item
              label="Password"
              name="password"
              style={{marginBottom: '15px'}}
            >
              <Input.Password placeholder="Enter your password" />
            </Item>

            <Form.Item>
              <Form.Item name="remember" valuePropName="checked" noStyle>
                <Checkbox>Remember me</Checkbox>
              </Form.Item>
              <a className="login-form-forgot" href="/forgot-password" style={{ float: 'right', color:'#183757' }}>
                Forgot password
              </a>
            </Form.Item>

            <Item>
              <Button type="primary" htmlType="submit" style={{width:'100%', boxShadow:'none'}}> 
                <span><b>Sign in</b></span>
              </Button>
            </Item>
            <Divider />
            <GoogleLoginButton />
            <div style={{ marginTop: '20px', textAlign: 'center' }}>
              <span>Don't have an account? </span>
              <a to="/signup">Sign up now</a>
            </div>
          </Form>
        </div>
      </Col>
    </Row>
  );
};

export default LoginPage;