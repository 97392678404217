import artical1img1 from './assets/artical1_image1.png';
import artical1img2 from './assets/artical1_image2.png';

import artical2img1 from './assets/artical2_image1.png';
import artical2img2 from './assets/artical2_image2.png';
import artical2img3 from './assets/artical2_image3.png';
import artical2img4 from './assets/artical2_image4.png';
import artical2img5 from './assets/artical2_image5.png';
export const newsData = [
  {
    id: 1,
    imageUrl: artical1img1,
    link: '/ourscience/1',
    type: 'webinar',
    title: "Webinar số 01 - Thang Điểm Nguy Cơ ĐA GEN: Một góc nhìn mới trong phòng ngừa bệnh tim mạch xơ vữa",
    content1: `
    Kính thưa các quý Bác sĩ/Dược sĩ,

    Mặc cho các tiến bộ trong chẩn đoán, điều trị, từ năm 1990 đến 2019, bệnh động mạch vành vẫn là một trong những nguyên nhân tử vong, cũng như giảm số năm sống có chất lượng (DALYs) hàng đầu trên thế giới và Việt Nam. Theo các hướng dẫn, phân tầng bằng các công cụ ước tính nguy cơ tim mạch và kiểm soát các yếu tố nguy cơ là chiến lược phòng ngừa phù hợp để giảm tử vong và tàn phế bệnh nhân. Bên cạnh các yếu tố nguy cơ truyền thống, các nghiên cứu về tương quan hệ gen đã góp phần làm sáng tỏ một số các biến thể di truyền có liên quan đến bệnh động mạch vành.

    Trong thập kỷ qua, các nổ lực phát triển và chuẩn hóa đã cho ra đời thang điểm nguy cơ đa gen, thang điểm này là thước đo giúp định lượng nguy cơ di truyền bằng cách tích hợp thông tin của số lượng lớn, lên đến hàng triệu các biến thể thành một con số duy nhất để phân tầng nguy cơ di truyền của một người. Với khả năng phân tầng, tiên lượng sớm bệnh động mạch vành, thang điểm này đã được sử dụng rộng rãi trên thế giới trong những năm gần đây, đặc biệt, Hiệp hội tim mạch Hoa kỳ (AHA 2022) đã đồng thuận về vai trò và ứng dụng thang điểm này trong thực hành lâm sàng. Chương trình hội thảo trực tuyến này sẽ giúp cập nhật về cơ chế phát triển và ứng dụng của thang điểm nguy cơ đa gen cho bệnh động mạch vành trong lâm sàng, cũng như chia sẽ chiến lược phòng ngừa bệnh tim mạch xơ vữa.

    Chủ tọa: GS.TS.BS. Trương Quang Bình – Chủ tịch Phân hội Xơ Vữa Động Mạch Việt Nam

    Báo cáo viên:

    · TS.BS. Nguyễn Thanh Huân – Phó khoa Nội Tim Mạch, Bệnh Viện Thống Nhất - BM Lão Khoa, ĐH Y Dược TP.HCM

    · TS.BS. Trần Hòa - Phó khoa Can Thiệp Tim Mạch, BV ĐH Y Dược TP.HCM - Tổng thư ký, Phân hội Xơ Vữa Động Mạch Việt Nam

    Tham gia thảo luận:

    · ThS.Bs Trần Công Duy - Khoa Nội Tim Mạch, Bệnh viện Chợ Rẫy - BM Nội tổng quát, ĐH Y Dược TP.HCM

    Thời gian tổ chức: Thứ Bảy ngày 13/01/2024 từ 13:30 đến 14:45.

    Hội Tim mạch xin mời các Bác sĩ có quan tâm tham gia và đóng góp ý kiến về Hội thảo.

    Trân trọng!
    `,
    youtubeContents: [
      {
        title: 'THANG ĐIỂM NGUY CƠ ĐA GEN: Góc Nhìn Mới Trong Dự Trong Dự Phòng Bệnh Tim Mạch Xơ Vữa',
        url: 'https://www.youtube.com/embed/i9FWKnuUZEY?si=CXry1xLMWA0gBFQ4'
      },
      {
        title: 'Góc Nhìn Mới Về Ứng Dụng THANG ĐIỂM NGUY CƠ ĐA GEN trong Phòng Ngừa Bệnh TMXV - TS.BS Trần Hoà',
        url: 'https://www.youtube.com/embed/ApNbmS7p0I8?si=Ve5vzdXLEfRGBEPw'
      },
      {
        title: 'Thang Điểm NGUY CƠ ĐA GEN: Khuynh Hướng Mới Trong Ước Tính Nguy Cơ Bệnh Mạch Vành | HPS',
        url: 'https://www.youtube.com/embed/a5FDw0PO5rU?si=YVfydaVwWl_Hb6Fm'
      },
    ],
    date: "October 4, 2024",
  },
  {
    id: 2,
    imageUrl: artical1img2,
    link: '/ourscience/2',
    type: 'artical',
    title: "SỰ KHÁC BIỆT VỀ DI TRUYỀN ĐƠN GEN VÀ ĐA GEN TRONG BỆNH LÝ TIM MẠCH",
    subTitle: "Differences between monogenic and polygenic in cardiovascular diseases",
    date: "October 4, 2024",
    description: `PGS.TS.BS Đỗ Đức Minh
Phó giám đốc Trung Tâm Y Sinh học Phân Tử - ĐH Y Dược TP.HCM
Khoa Nội Tiết – Bệnh Viện Chợ Rẫy`,
    contents: [
      {
        paragraph: `Các bệnh thường gặp như nhồi máu cơ tim, ung thư, đái tháo đường và các bệnh lý thoái hóa thần kinh gây ra gánh nặng bệnh tật lớn trên toàn thế giới. Nhiều bệnh lý trong số này có “tính gia đình” - các ca bệnh có vẻ như thường gặp ở thân nhân của người bệnh hơn so với dân số chung.
          Tuy nhiên, tính di truyền này thường không tuân theo một trong các mô hình di truyền trội/lặn Mendel truyền thống mà trong đó tổn thương di truyền tại một hoặc hai alen gây bệnh trên cùng một locus, thay vào đó, chúng là kết quả của các tương tác phức tạp giữa nhiều biến thể di truyền làm thay đổi tính nhạy cảm với bệnh tật, kết hợp với các điều kiện tiếp xúc với môi trường nhất định và đôi khi do hoàn cảnh ngẫu nhiên, tất cả các yếu tố này đồng thời có thể kích hoạt, đẩy nhanh, hoặc bảo vệ chống lại quá trình hình thành một bệnh lý. Vì lý do này, đa phần các bệnh lý phổ biến được xem là có nguồn gốc đa yếu tố, và các bệnh lý có nguy cơ gia tăng theo tính gia đình này có mô hình di truyền phức tạp đa yếu tố.
          Đối với các bệnh di truyền đơn gen theo mô hình Mendel truyền thống, tác động của kiểu gen lên kiểu hình rất rõ nét và các đột biến gen chịu trách nhiệm gần như hoàn toàn nguyên nhân dẫn đến bệnh lý. Tuy nhiên, trong thực tế bệnh di truyền đơn gen rất hiếm gặp do sự chọn lọc trong quá trình tiến hóa của loài người có khuynh hướng loại trừ các alen gây bệnh.
          Còn trong các bệnh lý phức tạp đa yếu tố, mối liên hệ kiểu gen - kiểu hình phức tạp hơn rất nhiều. Giữa các cá thể người, hơn 99% trình tự di truyền hoàn toàn giống nhau, chỉ một phần trình tự nhỏ (<1%, chiếm khoảng 3 triệu nucleotide) là khác biệt và chúng thường được gọi là các điểm đa hình. Các điểm đa hình đơn lẻ thường có ảnh hưởng rất ít đến kiểu hình cũng như tình trạng bệnh lý của các cá thể, tuy nhiên, khi tập hợp tất cả các điểm đa hình này cùng với các dữ liệu kiểu hình lâm sàng đủ lớn, ta sẽ có khả năng thấy được tác động của kiểu gen đối với kiểu hình. Mức độ phổ biến của các bệnh lý đơn gen và đa gen cũng như vai trò tác động của các đột biến/biến thể được thể hiện trong hình 1.`,
        img: artical1img1,
      },
      {
        paragraph: `
        Một ví dụ cụ thể về bệnh đơn gen (monogenic disease) đó là bệnh cơ tim dãn nở di truyền, bệnh thường do đột biến gen TTN. Gen này mã hóa một thành phần cấu trúc quan trọng của sarcome, là đơn vị cấu trúc của sợi cơ. Đột biến gen TTN làm cho sợi cơ tim mất đi tính đàn hồi và ổn định dẫn đến bệnh cơ tim giãn nở có tính gia đình.
        Ở chiều ngược lại, nhồi máu cơ tim là kết quả của nhiều quá trình sinh lý bệnh kết hợp bao gồm xơ vữa động mạch, tổn thương tế bào nội mạc, mất ổn định mảng xơ vữa, sự hình thành huyết khối. Với mỗi cơ chế như vậy sẽ có vai trò ảnh hưởng của hàng trăm biến thể từ nhiều gen khác nhau. Mỗi biến thể như vậy khi đứng riêng rẽ sẽ không gây bệnh hoặc có vai trò gây bệnh rất ít, nhưng khi chúng cộng hợp với nhau sẽ dẫn đến kiểu hình bệnh lý.
        Với sự phát triển của các công cụ khảo sát di truyền, số lượng và trọng số của các biến thể trong các bệnh lý di truyền đa gen phức tạp dần được xác định và từ đó giúp ta có thể xây dựng được một mô hình tiên lượng đa gen trong bệnh lý tim mạch.
        `,
        img: artical1img2,
      },
      {
        paragraph: `
        Lưu ý: Bài viết dùng cho mục đích cập nhật kiến thức chuyên ngành, không quảng bá, quảng cáo cho mục đích thương mại. Bệnh nhân vui lòng liên hệ đến bác sĩ để được tư vấn, thăm khám phù hợp.
        `,
      }
    ],
  },
  {
    id: 3,
    imageUrl: artical2img1,
    link: '/ourscience/3',
    type: 'artical',
    title: "ĐIỂM ĐA GEN ĐƯỢC TÍNH NHƯ THẾ NÀO?",
    subTitle: "HOW TO CALCULATE POLYGENIC SCORE (PGS)",
    date: "October 4, 2024",
    description: undefined,
    contents: [
      {
        paragraph: `Điểm Đa Gen (Polygenic score - PGS) được sử dụng để ước tính nguy cơ mắc các bệnh lý phổ biến liên quan đến nhiều gen khác nhau như bệnh mạch vành, đái tháo đường tuýp 2 và ung thư, được tính toán dựa trên hồ sơ gen của các cá thể được đánh giá và dữ liệu từ các nghiên cứu tương quan toàn bộ gen (genome-wide association study - GWAS). 

Tổng quan về các bước cần thiết để tính Điểm Đa Gen:

<b>Bước 1: Thu thập dữ liệu thống kê tóm tắt từ GWAS, trong đó nêu chi tiết về tác động của từng đa hình nucleotide đơn (Single-nucleotide polymorphisms - SNPs) đối với kiểu hình quan tâm.</b>

Sau khi xác định các SNP liên quan, mỗi SNP được gán một trọng số dựa trên mức độ ảnh hưởng của nó đối với nguy cơ mắc bệnh. Trọng số này được tính toán từ tỷ lệ Odds (log(ORs)) từ các phân tích GWAS. Những SNP có mối liên hệ mạnh với bệnh sẽ có trọng số lớn, trong khi các SNP có mối liên hệ yếu hơn sẽ có trọng số nhỏ hơn.

Xét một thống kê của GWAS, ta thấy có 1 vị trí tên là SNP1 - màu đỏ với mã alen A và có số kích thước hiệu ứng là +1.5, điều này có nghĩa là đa hình nucleotide đơn này - SNP làm tăng biểu hiện của tính trạng với trọng số +1.5. SNP2 - màu xanh dương và có trọng số -0.5, điều này có nghĩa là đa hình nucleotide đơn - SNP làm giảm biểu hiện của tính trạng với trọng số -0.5. Tương tự với SNP3 và SNP4.`,
        img: artical2img1,
      },
      {
        paragraph: `
        Việc xác định kích thước hiệu ứng của các SNP này là rất quan trọng, vì chúng sẽ đóng vai trò cơ sở trong việc tính toán Điểm Đa Gen trong các bước tiếp theo.
        `,
      },
      {
        paragraph: `
        <b>Bước 2: Dữ liệu kiểu gen của các cá thể được tham chiếu dựa trên bản tóm tắt số liệu thống kê của GWAS.</b>
        Giả sử chúng ta có quần thể là 3 người được giải trình tự tại 4 vị trí SNP đã nghiên cứu ở trên với kết quả như hình bên dưới: "
        `,
        img: artical2img2,
      },
      {
        paragraph: `
        <b>Bước 3: Điểm Đa Gen được tính bằng công thức đơn giản của hàm tổng của tần số alen nhỏ nhân với trọng số của nó.</b>
        `,
        img: artical2img3,
        imgWidth: '20%',
      },
      {
        paragraph: `Với: 
x là tần số alen nhỏ có giá trị: 0, 1, 2
β là kích thước hiệu ứng, được trích xuất từ các nghiên cứu tương quan toàn bộ gen - GWAS.

Do đó Điểm Đa Gen của 3 người trong quần thể được tính như sau: 
`,
        img: artical2img4,
      },
      {
        paragraph: `
        <b>Bước 4: Sau khi có Điểm Đa Gen, chúng ta sẽ biết mỗi người nằm trong vùng nguy cơ như thế nào.</b>
        Lấy bệnh lý động mạch vành làm ví dụ, dựa trên Điểm Đa Gen của quần thể đã được tính toán ở bước trước:
        Người thứ nhất có Điểm Đa Gen là 2.0, sẽ có nguy cơ về gen cao, điều này đồng nghĩa với việc người này có khả năng mắc bệnh động mạch vành cao.
        Còn người thứ hai và người thứ ba có Điểm Đa Gen lần lượt là -0.5 và -4  thuộc mức trung bình, cho thấy nguy cơ về gen trung bình mắc bệnh động mạch vành.
        
        Điểm Đa Gen là một công cụ quan trọng trong y học cá thể hóa, với tiềm năng được tích hợp vào thực hành lâm sàng. Khi kết hợp điểm đa gen với các yếu tố nguy cơ truyền thống như tiền sử gia đình, lối sống sẽ giúp tăng đáng kể độ chính xác và cảnh báo sớm nguy cơ mắc các bệnh lý phức tạp từ đó giúp các chuyên gia y tế có những cân nhắc sớm về việc điều trị và giáo dục bệnh nhân thay đổi lối sống.`,
        img: artical2img5,
      },
      {
        paragraph: `
        Tài liệu tham khảo:
        1. Choi, S.W., Mak, T.SH. & O’Reilly, P.F. Tutorial: a guide to performing polygenic risk score analyses. Nat Protoc 15, 2759–2772 (2020). https://doi.org/10.1038/s41596-020-0353-1 
        2. Tam, V., Patel, N., Turcotte, M. et al. Benefits and limitations of genome-wide association studies. Nat Rev Genet 20, 467–484 (2019). https://doi.org/10.1038/s41576-019-0127-1
        3. Khera, A.V., Chaffin, M., Aragam, K.G. et al. Genome-wide polygenic scores for common diseases identify individuals with risk equivalent to monogenic mutations. Nat Genet 50, 1219–1224 (2018). https://doi.org/10.1038/s41588-018-0183-z
        
        
        Lưu ý: Bài viết dùng cho mục đích cập nhật kiến thức chuyên ngành, không quảng bá, quảng cáo cho mục đích thương mại. Bệnh nhân vui lòng liên hệ đến bác sĩ để được tư vấn, thăm khám phù hợp.`,
      }

    ],
  },
];
