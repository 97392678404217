import React, { useEffect, useState } from 'react';
import { Table, Button, Modal, Form, Input, Typography, Card, message } from 'antd';
import 'antd/dist/reset.css'; // Ensure Ant Design styles are imported
import { fetchWithToken } from '../share/apiUtils';
import { backendUrl } from '../../constants';

const { Title } = Typography;
const AccountPage = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [isFormValid, setIsFormValid] = useState(false);

  const [users, setUsers] = useState([
    // Add more users as needed
  ]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const fetchUsers = async () => {
    // Fetch users from the API
    const url = `${backendUrl}/users`;
    const response = await fetchWithToken(url, { method: 'GET' });
    if(response.ok) {
      const data = await response.json();
      setUsers(data);
    }
  };

  const createUser = async (values) => {
    const url = `${backendUrl}/create_user`;
    const response = await fetchWithToken(url, {
      method: 'POST',
      body: JSON.stringify(values),
    });

    if (response.ok) {
      message.success('User created successfully!', 2);
      fetchUsers();
    } else {
      const data = await response.json();
      message.error(data.error, 2);
    }
  }

  useEffect(() => {
    form.resetFields();
  }, [isModalVisible]);

  useEffect(() => {
    fetchUsers();
  }, []);

  const handleOk = () => {
    const values = form.getFieldsValue();
    createUser(values);
    setIsModalVisible(false);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'User name',
      dataIndex: 'username',
      key: 'username',
    },

  ];
  const handleValuesChange = (changedValues) => {
    // Check if email is empty to disable or enable the OK button
    const email = form.getFieldValue('email');
    const username = form.getFieldValue('username');
    setIsFormValid(email && email.trim() !== '' && username && username.trim() !== '');
  };

  return (
    <div style={{width:'800px', height:'100vh'}}>
      <div>
        <Title level={4} style={{textAlign:'left'}}>Tài khoản</Title>
      </div>
      <Card bordered={false} style={{padding:0, textAlign:'right'}} >
        <Button type="primary" onClick={showModal} style={{margin:'20px'}}>
          Add User
        </Button>
        <Table columns={columns} dataSource={users} />
      </Card>

      
      <Modal
        title="Create User"
        open={isModalVisible}
        onCancel={handleCancel}
        onOk={handleOk}
        okText="Create"
        cancelText="Cancel"
        okButtonProps={{ disabled: !isFormValid }}
      >
        <Form
          form={form}
          layout="vertical"
          name="userForm"
          initialValues={{ remember: true }}
          onValuesChange={handleValuesChange}
        >
          <Form.Item
            name="email"
            label="Email"
            rules={[{ required: true, message: 'Please enter the user email!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="username"
            label="Username"
            rules={[{ required: true, message: 'Please enter the user email!' }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default AccountPage;
