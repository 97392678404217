import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LoginPage from './components/Login/LoginPage';
import './App.css';
import DashboardPage from './components/Dashboard/DashboardPage';
import TestPage from './components/Test/TestPage';
import LoginLayout from './components/Layout/LoginLayout';
import MainLayout from './components/Layout/MainLayout';
import AccountPage from './components/Account/AccountPage';
import LandingPage from './components/LandingPage/LandingPage';
import NewsDetailsPage from './components/LandingPage/New/NewsDetailsPage';
import OurSciencePage from './components/LandingPage/OurScience/OurSciencePage';
import ListNewPage from './components/LandingPage/New/ListNewPage';

const App = () => {
  return (
    <Router>
      <Routes>
        {/* Route for the login page */}
        <Route path="/" element={ <LandingPage />}/>
        <Route path="/news/:newsId" element={ <NewsDetailsPage />}/>
        <Route path="/news" element={ <ListNewPage />}/>
        <Route path="/ourscience/:scienceId" element={ <OurSciencePage />}/>
        <Route path="/login" element={<LoginLayout><LoginPage /></LoginLayout>} />

        <Route path="/dashboard" element={<MainLayout><DashboardPage /></MainLayout>} />
        <Route path="/create-test" element={<MainLayout><TestPage /></MainLayout>} />
        <Route path="/edit-test" element={<MainLayout><TestPage /></MainLayout>} />
        <Route path="/account" element={<MainLayout><AccountPage /></MainLayout>} />
      </Routes>
    </Router>
  );
};

export default App;
   