import React from 'react';
import { Card, Col, Row } from 'antd';
import { newsData } from './data/data';


const News = () => {
  return (
    <div style={{ padding: '20px', textAlign:'center' }}>
      <h2> News </h2>
      <Row gutter={[20, 20]} justify="center"> {/* Sets padding between items to 10px */}
        {newsData.map((item, index) => (
          <Col xs={24} sm={12} md={8} lg={6} xl={6} key={index} style={{ padding: '5px' }}> {/* Half of the gutter value on each side */}
            <Card
              hoverable
              onClick={() => window.location.href = item.link}
              title={item.title}
              cover={<img alt="news" src={item.imageUrl} />} // Optional: Add image if needed
            >
            </Card>
          </Col>
        ))}
      </Row>

    </div>
  );
};

export default News;
