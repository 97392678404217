import React, {useState, useEffect} from "react";
import { useParams } from "react-router-dom";
import { Card, Typography, Image, Grid, Breadcrumb } from "antd";
import NavigationLandingPage from "../Layout/NavigationLandingPage";
import FooterLandingPage from "../Layout/FooterLandingPage";
import { newsData }  from "../data/data";

const { Title, Paragraph } = Typography;

const { useBreakpoint } = Grid;

const NewsDetailsPage = () => {
  const { newsId } = useParams(); // Get the news ID from the route
  const [bannerHeight, setBannerHeight] = useState('auto');
  const [headerHeight, setHeaderHeight] = useState(0);
  const screens = useBreakpoint(); // Get the current screen size

  useEffect(() => {
    const handleResize = () => {
      // Calculate available height for banner
      const headerHeight = document.querySelector('.header').offsetHeight;
      const footerHeight = document.querySelector('.footer-component').offsetHeight;
      setHeaderHeight(headerHeight);
      setBannerHeight(`calc(100vh - ${headerHeight+footerHeight}px)`);
    };

    // Set initial height
    handleResize();
    
    // Add resize event listener
    window.addEventListener('resize', handleResize);

    // Clean up on unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Find the specific news item based on the ID
  const newsItem = newsData.find((item) => item.id === parseInt(newsId));

  if (!newsItem) {
    return <div>News not found</div>;
  }

  return (
    <>
      <NavigationLandingPage />
      <div style={{paddingTop:`${headerHeight}px`, minHeight:bannerHeight, padding: `${headerHeight}px ${screens.md?200:30}px`}}>
        <Breadcrumb style={{margin:'10px 0'}}>
          <Breadcrumb.Item>
            <a href="/">Home</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item href="/news"> News</Breadcrumb.Item>
          <Breadcrumb.Item> {newsItem.title}</Breadcrumb.Item>
        </Breadcrumb>
        <div>
        <Title level={2}>{newsItem.title}</Title>
        <Paragraph>{newsItem.date}</Paragraph>
        {newsItem.content1.split('\n').map((line, index) => (
          <Paragraph key={index}>{line}</Paragraph>
        ))}
        <Image
          src={newsItem.imageUrl}
          alt={newsItem.title}
          style={{ margin: "24px 0" }}
        />
        {newsItem.content2.split('\n').map((line, index) => (
          <Paragraph key={index}>{line}</Paragraph>
        ))}
        </div>  
      </div>
      <FooterLandingPage />
    </>

  );
};

export default NewsDetailsPage;