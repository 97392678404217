import React from 'react';
import { Form, Input, Checkbox, Radio, Button, Col, Row } from 'antd';

const ClinicalInput = () => {
  return (
    <>
      {/* Question 1: Has patient had an MI/CAD? */}
      <Form.Item label="1. Bệnh nhân đã trải qua nhồi máu cơ tim hoặc có mắc bệnh động mạch vành?" name={['clinical', 'has_mi_cad']}>
        <Radio.Group>
          <Radio value={true}>Có</Radio>
          <Radio value={false}>Không</Radio>
        </Radio.Group>
      </Form.Item>

      {/* Question 2a: Diabetes */}
      <Form.Item label="2a. Bệnh nhân có tiền sử mắc Đái tháo đường?" name={['clinical', "has_diabetes"]}>
        <Radio.Group>
          <Radio value={true}>Có</Radio>
          <Radio value={false}>Không</Radio>
        </Radio.Group>
      </Form.Item>

      {/* Question 2b: Hypertension */}
      <Form.Item label="2b. Bệnh nhân có tiền sử mắc Cao huyết áp?" name={["clinical", "has_hypertension"]}>
        <Radio.Group>
          <Radio value={true}>Có</Radio>
          <Radio value={false}>Không</Radio>
        </Radio.Group>
      </Form.Item>

      {/* Question 3: Blood lipid levels */}
      <Form.Item label="3. Chỉ số lipid máu tính theo mg/dl hoặc mmol/l của bệnh nhân tại lần kiểm tra gần nhất">
        <Input.Group compact>
          <Form.Item style={{marginRight:10}} name={["clinical", "total_cholesterol"]} label="Total cholesterol">
            <Input placeholder="Total cholesterol" />
          </Form.Item>
          <Form.Item style={{marginRight:10}} name={['clinical', "ldl"]} label="LDL">
            <Input placeholder="LDL" />
          </Form.Item>
          <Form.Item style={{marginRight:10}} name={['clinical', "hdl"]} label="HDL">
            <Input placeholder="HDL" />
          </Form.Item>
          <Form.Item style={{marginRight:10}} name={['clinical', "tg"]} label="TG">
            <Input placeholder="TG" />
          </Form.Item>
        </Input.Group>
      </Form.Item>

      {/* Question 4: Medications for heart disease */}
      <Form.Item label="4. Bệnh nhân đang sử dụng các thuốc điều trị các bệnh lý tim mạch hay không?" name={['clinical', 'takes_medications']}>
        <Radio.Group style={{marginBottom:10}}>
          <Radio value={true}>Có</Radio>
          <Radio value={false}>Không</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item label="Nếu có, vui lòng cho biết các thuốc đang sử dụng:" name={['clinical', 'takes_medications_options']}>
          <Checkbox.Group>
            <Checkbox value="anticoagulants">Thuốc chống đông</Checkbox>
            <Checkbox value="cholesterol_meds">Thuốc điều trị tăng lipid máu</Checkbox>
            <Checkbox value="blood_pressure_meds">Thuốc điều trị tăng huyết áp</Checkbox>
            <Checkbox value="others">Thuốc khác</Checkbox>
          </Checkbox.Group>
          
        </Form.Item>
        <Form.Item name={['clinical', 'other_meds']}>
          <Input.TextArea placeholder="Specify other medications" />
        </Form.Item>

      {/* Question 5: Parent with early ASCVD */}
      <Form.Item label="5. Bệnh nhân có cha/mẹ mắc ASCVD sớm hay không?" name={['clinical', 'parent_with_ascvd']}>
        <Radio.Group>
          <Radio value={true}>Có</Radio>
          <Radio value={false}>Không</Radio>
        </Radio.Group>
      </Form.Item>

      {/* Question 6: Smoking and Drinking habits */}
      <Form.Item label="6. Bệnh nhân có các thói quen sau đây hay không?">
      <Row >
        
          <Col span={4}>
            <Form.Item label="a) Hút thuốc lá:" name={['clinical', "smokes"]}>
              <Radio.Group>
                <Radio value={true}>Có</Radio>
                <Radio value={false}>Không</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="b) Uống rượu bia:" name={['clinical', "drinks_alcohol"]}>
              <Radio.Group>
                <Radio value={true}>Có</Radio>
                <Radio value={false}>Không</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
      </Row>
      </Form.Item>


      {/* Question 7: Clinical notes/diagnosis */}
      <Form.Item label="7. Các ghi chú lâm sàng/chẩn đoán" name={['clinical', "clinical_notes"]}>
        <Input.TextArea placeholder="Please enter any clinical notes or diagnosis" />
      </Form.Item>
    </>
  );
};

export default ClinicalInput;