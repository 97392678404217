import React, {useState, useEffect} from "react";
import { useParams } from "react-router-dom";
import { Card, Typography, Image, Grid, Breadcrumb } from "antd";
import NavigationLandingPage from "../Layout/NavigationLandingPage";
import FooterLandingPage from "../Layout/FooterLandingPage";
import { newsData }  from "../data/data";

const { Title, Paragraph } = Typography;


const { useBreakpoint } = Grid;

const OurSciencePage = () => {
  const { scienceId } = useParams(); // Get the news ID from the route
  const [bannerHeight, setBannerHeight] = useState('auto');
  const [headerHeight, setHeaderHeight] = useState(0);
  const screens = useBreakpoint(); // Get the current screen size

  useEffect(() => {
    const handleResize = () => {
      // Calculate available height for banner
      const headerHeight = document.querySelector('.header').offsetHeight;
      const footerHeight = document.querySelector('.footer-component').offsetHeight;
      setHeaderHeight(headerHeight);
      setBannerHeight(`calc(100vh - ${headerHeight+footerHeight}px)`);
    };

    // Set initial height
    handleResize();
    
    // Add resize event listener
    window.addEventListener('resize', handleResize);

    // Clean up on unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Find the specific news item based on the ID
  const newsItem = newsData.find((item) => item.id === parseInt(scienceId));

  return (
    <>
      <NavigationLandingPage />
      <div style={{minHeight:bannerHeight, padding: `${headerHeight}px ${screens.md?100:30}px`}}>
        <Breadcrumb style={{margin:'10px 0'}}>
          <Breadcrumb.Item>
            <a href="/">Home</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Our Science</Breadcrumb.Item>
        </Breadcrumb>
        <div>
        <div style={{padding: screens.md? '0 30px': 0}}>
        {newsItem ? (<>
          <Title style={{marginBottom:0}} level={2}>{newsItem.title}</Title>
          {newsItem.subTitle && <Title style={{marginTop:'0'}} level={4}>{newsItem.subTitle}</Title>}
          <Paragraph style={{ fontSize: "16px" }}>{newsItem.date}</Paragraph>
          {newsItem.description && <Paragraph style={{ fontSize: "16px", whiteSpace:'pre-wrap' }}>{newsItem.description}</Paragraph>}
          {newsItem.contents && newsItem.contents.map((content, index) => (
            <>
            {content.paragraph && content.paragraph.split('\n').map((line, index) => (
              <Paragraph style={{ fontSize: "16px" }} key={index}>
                <span dangerouslySetInnerHTML={{ __html: line }} />
              </Paragraph>
            ))}
            {content.img && <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
              <img alt="" src={content.img} style={{ width:content.imgWidth||'80%', alignItems:'center', margin: '10px auto' }} />
            </div>}
            </>
          ))}
          
          {newsItem.youtubeContents && newsItem.youtubeContents.map((video, index) => (
            <Card
              key={index}
              title={video.title}
              bordered={false}
              style={{ width: '100%', maxWidth: 600, margin: '24px auto' }}
            >
              <div style={{ position: 'relative', paddingBottom: '56.25%', height: 0, overflow: 'hidden', maxWidth: '100%', background: '#000' }}>
                <iframe
                  width="100%"
                  height="100%"
                  src={video.url}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title={video.title}
                  style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                />
              </div>
            </Card>
          ))}
        </>) : <div>Content not found</div>}
        </div>
        </div>
      </div>
      <FooterLandingPage />
    </>

  );
};

export default OurSciencePage;