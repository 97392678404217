import React, {useState, useEffect} from 'react';
import { Upload, message } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { backendUrl } from '../../constants';

const { Dragger } = Upload;

const UploadFileComponent = ({ initListFile, onResetFiles=(e)=>{} }) => {
  useEffect(() => {
    setFileListState(initListFile);
  }, [initListFile]);
  const [fileListState, setFileListState] = useState(initListFile);
  const url = `${backendUrl}/upload`;
  const props = {
    fileList: fileListState,
    name: 'files',
    multiple: true,
    action: url,
    headers:{
      Authorization: `Bearer ${localStorage.getItem('token')}`
    },
    onChange(info) {
      const { status } = info.file;
      setFileListState(info.fileList);
      if (status === 'removed') {
        onResetFiles(info.fileList);
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
        onResetFiles(info.fileList);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
    },
  };

  return (
    <div>
     <Dragger {...props}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">Click or drag file to this area to upload</p>
        <p className="ant-upload-hint">
          Support for a single or bulk upload. Strictly prohibit from uploading company data or other
          band files.
        </p>
      </Dragger>
    </div>
 
  );
};

export default UploadFileComponent;