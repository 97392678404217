import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { GoogleOAuthProvider } from '@react-oauth/google';
import 'antd/dist/reset.css'; // Import the Ant Design CSS
import { ConfigProvider } from 'antd';

const theme = {
  token: {
    colorPrimary: '#183757', // Custom primary color
  },
};

const clientId = '323081154238-jgjf5gfh46ictagl0agcgjmqpn0nmifl.apps.googleusercontent.com'
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <GoogleOAuthProvider clientId={clientId}>
    <ConfigProvider theme={theme}>
      <App />
    </ConfigProvider>
  </GoogleOAuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
